<template>
  <div class="flex flex-col w-full items-center">
    <img
      class="h-[215px]"
      src="../../assets/img/logoProfesorsChallange.png"
      alt="challenge's logo"
    />
    <div class="p-4 rounded-[12px] bg-neutral_700 w-full">
      <div>
        <form class="flex flex-col gap-3" @submit.prevent="onPredictionSubmit">
          <div class="flex flex-col gap-3" :class="props.place == 'indexPage' ? 'sm:flex-row' : ''">
            <InputText
              v-model="pair"
              size="small"
              :placeholder-text="t('pairAndGame')"
              :error="v$.pair.$error"
              :error-messages="v$.pair.$errors"
              :disabled="userStore.monthNumberOfPredictions >= 45"
              @blur="savePredictionValues"
            />
            <InputText
              v-model="odd"
              size="small"
              :class="props.place == 'indexPage' ? 'sm:max-w-[100px]' : ''"
              :placeholder-text="t('odd')"
              :error="v$.odd.$error"
              :error-messages="v$.odd.$errors"
              :disabled="userStore.monthNumberOfPredictions >= 45"
              @blur="savePredictionValues"
            />
            <div
              class="flex items-center mt-3 justify-between"
              :class="props.place == 'indexPage' ? 'sm:mt-0 sm:h-10' : ''"
            >
              <ButtonComponent
                class="flex"
                :class="props.place == 'indexPage' ? 'sm:hidden' : ''"
                type="text"
                :text="t('whatIsThis')"
                @click="commonStore.setIsCompetitionFaqOpen(true)"
              />
              <ButtonComponent
                v-if="userStore.monthNumberOfPredictions < 45"
                class="shrink-0 h-8"
                :class="props.place == 'indexPage' ? 'sm:h-10' : ''"
                :form-element="true"
                :text="t('predict')"
                :is-loading="isLoading"
              />
            </div>
            <div
              class="w-px h-10 hidden bg-primary_800"
              :class="props.place == 'indexPage' ? 'sm:block' : ''"
            />
            <div
              class="h-10 hidden items-center justify-center p-3"
              :class="props.place == 'indexPage' ? 'sm:flex' : ''"
            >
              <ClientOnly>
                <ion-icon
                  class="text-[24px] cursor-pointer text-primary_500"
                  name="help-circle-sharp"
                  @click="commonStore.setIsCompetitionFaqOpen(true)"
                />
              </ClientOnly>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <RepostPredictionDialog
    v-if="predictionToRepost"
    :prediction="predictionToRepost"
    :is-open="isRepostDialogOpen"
    @close-dialog="isRepostDialogOpen = false"
  />
</template>
<script lang="ts" setup>
import { useCommonStore } from '~/store/commonStore';
import useVuelidate from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { challengeRepository } from '@/repositories/challengeRepository';
import { useDialogStore } from '~/store/dialogStore';
import type { UserPrediction } from '~/interfaces';
import { useUserStore } from '~/store/user';
interface StoragePrediction {
  game: string;
  odd: string;
}

const emits = defineEmits(['prediction-created']);
const props = defineProps({
  place: {
    type: String,
    default: 'indexPage'
  }
});
const { $api } = useNuxtApp();
const { $event } = useNuxtApp();
const {
  value: predictionStorageObject,
  saveValue: savePrediction,
  loadValue: loadPrediction,
  clearValue: clearPredictionStorage
} = useTmpLocalStorage<StoragePrediction>('userTmpPrediction');
const { value: storageHideDialog, loadValue: loadHideDialog } = useTmpLocalStorage<boolean>(
  'hideRepostPredictionDialog'
);
const challengeRepo = challengeRepository($api);
const { t } = useI18n();
const commonStore = useCommonStore();
const dialogStore = useDialogStore();
const userStore = useUserStore();
const pair = ref('');
const odd = ref<string>('');
const isLoading = ref(false);
const isRepostDialogOpen = ref(false);
const predictionToRepost = ref<UserPrediction | null>(null);
const hideRepostDialogAfterAddingPrediction = ref(false);

const isNumberBetween1And100 = helpers.regex(/^(?:1[.,]0*[1-9]\d*|[2-9]|\d{2})([.,]\d+)?$/);

const rules = {
  pair: {
    required: helpers.withMessage(t('requiredError', [t('pairAndGame')]), required),
    maxLength: helpers.withMessage(t('maxLengthError', { n: '100' }), maxLength(100))
  },
  odd: {
    required: helpers.withMessage(t('requiredError', [t('odd')]), required),
    isNumber: helpers.withMessage(t('oddNotANumberError'), isNumberBetween1And100)
  }
};

const v$ = useVuelidate(rules, { pair, odd });

onMounted(() => {
  loadPrediction();
  if (predictionStorageObject.value) {
    pair.value = predictionStorageObject.value.game as string;
    odd.value = predictionStorageObject.value.odd;
  }
  loadHideDialog();
  if (storageHideDialog.value)
    hideRepostDialogAfterAddingPrediction.value = storageHideDialog.value;
});

const onPredictionSubmit = async () => {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    try {
      isLoading.value = true;
      const newPrediction = await challengeRepo.createPrediction(
        pair.value,
        parseFloat(odd.value.replace(',', '.'))
      );
      isLoading.value = false;
      pair.value = '';
      odd.value = '';
      v$.value.$reset();
      if (newPrediction.status == 'SUCCESS') {
        userStore.increaseWeekNumberOfPredictions();
        userStore.increaseMonthNumberOfPredictions();
        dialogStore.createToasterNotification(
          'success',
          `${t('notification')}`,
          `${t('predictionSuccessMessage')}`,
          6000
        );
      } else if (
        newPrediction.status == 'ERROR' &&
        newPrediction.message == 'WEEK_PREDICTION_LIMIT_REACHED'
      ) {
        dialogStore.createToasterNotification(
          'warning',
          `${t('notification')}`,
          `${t('predictionWeekLimit')}`
        );
        userStore.increaseMonthNumberOfPredictions();
      }
      if (!hideRepostDialogAfterAddingPrediction.value) {
        predictionToRepost.value = newPrediction.prediction;
        isRepostDialogOpen.value = true;
      }
      emits('prediction-created', newPrediction.prediction);
      clearPredictionStorage();
      $event('user:addedNewPrediction', newPrediction);
    } catch (error: any) {
      if (error.statusCode == 403) {
        userStore.setIsUserBlockedFromCompetition(true);
      }
      isLoading.value = false;
      console.error(error);
    }
  }
};
const savePredictionValues = () => {
  savePrediction({
    game: pair.value,
    odd: odd.value
  });
};
</script>
